export default [
  // {
  //   path: '/dashboard/analytics',
  //   name: 'dashboard-analytics',
  //   component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  //   meta: {
  //     action: 'view',
  //     resource: 'dashboard',
  //   },
  //
  // },
  // {
  //   path: '/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  //   meta: {
  //     action: 'view',
  //     resource: 'dashboard',
  //   },
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      action: 'view',
      resource: 'dashboard',
    },

  },
]
