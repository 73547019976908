import axios from '@axios'

export default {
  namespaced: true,
  state: {
    user: '',
    dark_mode: '',
  },
  getters: {},
  mutations: {
    UPDATE_DARK(state, data) {
      state.dark_mode = data
    },
    UPDATE_USER(state, data) {
      state.user = data
    },
  },
  actions: {
    updateDark(state, data) {
      const userId = state.state.user.id
      const { skin } = data
      return new Promise((resolve, reject) => {
        axios
          .post('/change-dark-mode', { dask: skin, id: userId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
