export default [
  {
    path: '/bank-questions/list',
    name: 'file-list',
    component: () => import('@/views/capacity/bank-questions/List.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Quản lý tập tin',
          active: true,
        },
      ],
    },

  },
]
