export default [
  {
    path: '/quiz/multiple-choice',
    name: 'multiple-choice',
    component: () => import('@/views/quiz/multipeChoice.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Ngân hàng câu hỏi',
          to: { name: 'bank-questions-list' },
        },
        {
          text: 'Câu hỏi trắc nghiệm',
          active: true,
        },
      ],
    },

  },
  {
    path: '/quiz/recording',
    name: 'recording',
    component: () => import('@/views/quiz/recording.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Ngân hàng câu hỏi',
          to: { name: 'bank-questions-list' },
        },
        {
          text: 'Câu hỏi ghi âm',
          active: true,
        },
      ],
    },

  },
  {
    path: '/quiz/fill-answer',
    name: 'fill-answer',
    component: () => import('@/views/quiz/fillAnswer.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Ngân hàng câu hỏi',
          to: { name: 'bank-questions-list' },
        },
        {
          text: 'Câu hỏi điền từ',
          active: true,
        },
      ],
    },

  },
  {
    path: '/quiz/compound-word',
    name: 'compound-word',
    component: () => import('@/views/quiz/compoundWord.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Ngân hàng câu hỏi',
          to: { name: 'bank-questions-list' },
        },
        {
          text: 'Câu hỏi ghép từ',
          active: true,
        },
      ],
    },

  },
  {
    path: '/quiz/column-merge',
    name: 'column-merge',
    component: () => import('@/views/quiz/columnMerge.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Ngân hàng câu hỏi',
          to: { name: 'bank-questions-list' },
        },
        {
          text: 'Câu hỏi ghép cột',
          active: true,
        },
      ],
    },

  },
]
