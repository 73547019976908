export default [
  {
    path: '/bank-questions/list',
    name: 'bank-questions-list',
    component: () => import('@/views/capacity/bank-questions/List.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Ngân hàng câu hỏi',
          active: true,
        },
      ],
    },

  },
  {
    path: '/bank-questions/edit/:id?/:title?',
    name: 'bank-question-edit',
    component: () => import('@/views/capacity/bank-questions/Edit.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Ngân hàng câu hỏi',
          to: { name: 'bank-questions' },
        },
        {
          text: null,
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[1].text = to.params.name ? to.params.name : 'Thêm mới'
      next()
    },
  },
]
