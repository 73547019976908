export default [
  {
    path: '/banner/list',
    name: 'banner-list',
    component: () => import('@/views/website/banner/List.vue'),
    meta: {
      action: 'view',
      resource: 'website',
      breadcrumb: [
        {
          text: 'Quản lý banner',
          active: true,
        },
      ],
    },

  },
]
