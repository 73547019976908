import Vue from 'vue'

// axios
import axios from 'axios'

// eslint-disable-next-line no-unused-vars,import/no-cycle
import useJwt from '@/auth/jwt/useJwt'

// eslint-disable-next-line import/no-cycle
import router from '@/router'

const axiosIns = axios.create({
  baseURL: `${process.env.VUE_APP_API_ENDPOINT}/api`,
})

axiosIns.interceptors.response.use(response => response, error => {
  if (error?.response?.status === 401) {
    if (error?.response?.data?.error === 'token_has_expired') {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      router.push({ name: 'auth-login' })
    }
  }

  if (error?.response?.status === 403) {
    router.push({ name: 'dashboard-ecommerce' })
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
