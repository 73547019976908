export default [
  {
    path: '/tag-question',
    name: 'tag-question',
    component: () => import('@/views/capacity/tagQuestions/List.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Danh sách nhãn câu hỏi',
          active: true,
        },
      ],
    },

  },
]
