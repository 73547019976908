export default [
  {
    path: '/user-activity',
    name: 'user-activity',
    component: () => import('@/views/user/user-activity/List.vue'),
    meta: {
      action: 'view',
      resource: 'permission',
      breadcrumb: [
        {
          text: 'Danh sách hoạt động',
          active: true,
        },
      ],
    },

  },
]
