export default [
  {
    path: '/exams/result',
    name: 'exam-result',
    component: () => import('@/views/capacity/exams/Result.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Danh sách kết quả thi & bài tập',
          active: true,
        },
      ],
    },

  },
  {
    path: '/exams/result/:id?',
    name: 'exam-result-detail',
    component: () => import('@/views/capacity/exams/ResultDetail.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Danh sách kết quả thi & bài tập',
          to: { name: 'exam-result' },
        },
        {
          text: null,
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[1].text = to.params.account ? to.params.account.name : 'Chi tiết'
      next()
    },
  },
  {
    path: '/exams/exercise/:id?',
    name: 'exam-exercise-detail',
    component: () => import('@/views/capacity/exams/ResultExerciseDetail.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Danh sách kết quả thi & bài tập',
          to: { name: 'exam-result' },
        },
        {
          text: null,
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[1].text = to.params.account ? to.params.account.name : 'Chi tiết'
      next()
    },
  },
]
