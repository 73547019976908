export default [
  {
    path: '/course/list',
    name: 'course-list',
    component: () => import('@/views/courses/List.vue'),
    meta: {
      action: 'view',
      resource: 'course',
      breadcrumb: [
        {
          text: 'Danh sách khóa học',
          active: true,
        },
      ],
    },

  },
  {
    path: '/courses/edit/:id?/:title?',
    name: 'course-edit',
    component: () => import('@/views/courses/Edit.vue'),
    meta: {
      action: 'view',
      resource: 'course',
      breadcrumb: [
        {
          text: 'Khóa học',
          to: { name: 'course-list' },
        },
        {
          text: null,
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[1].text = to.params.name ? to.params.name : 'Thêm mới'
      next()
    },
  },

]
