export default [
  {
    path: '/exams/list',
    name: 'exam-list',
    component: () => import('@/views/capacity/exams/List.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Danh sách đề thi',
          active: true,
        },
      ],
    },

  },
  {
    path: '/exams/list',
    name: 'exam-list',
    component: () => import('@/views/capacity/exams/List.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Đề thi',
          active: true,
        },
      ],
    },

  },

  {
    path: '/exams/edit/:id?/:title?',
    name: 'exam-edit',
    component: () => import('@/views/capacity/exams/Edit.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Đề thi',
          to: { name: 'exam-list' },
        },
        {
          text: null,
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[1].text = to.params.name ? to.params.name : 'Thêm mới'
      next()
    },
  },

]
