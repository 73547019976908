export default [
  {
    path: '/users/list',
    name: 'users-list',
    component: () => import('@/views/system-manager/admin-account/List.vue'),
    meta: {
      action: 'view',
      resource: 'permission',
      breadcrumb: [
        {
          text: 'Danh sách tài khoản quản trị',
          active: true,
        },
      ],
    },
  },
  {
    path: '/roles/list',
    name: 'roles-list',
    component: () => import('@/views/system-manager/permission/List.vue'),
    meta: {
      action: 'view',
      resource: 'permission',
      breadcrumb: [
        {
          text: 'Danh sách quyền',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/view/:id',
    name: 'users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      action: 'view',
      resource: 'admin',
    },
  },
  {
    path: '/roles/edit/:id?',
    name: 'roles-edit',
    component: () => import('@/views/system-manager/permission/Edit.vue'),
    meta: {
      action: 'view',
      resource: 'permission',
      breadcrumb: [
        {
          text: 'Danh sách quyền',
          to: { name: 'roles-list' },
        },
        {
          text: null,
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[1].text = to.params.name ? to.params.name : 'Thêm mới'
      next()
    },

  },
  {
    path: '/users/edit',
    name: 'users-edit',
    component: () => import('@/views/system-manager/admin-account/Setiing'),
    meta: {
      action: 'view',
      resource: 'dashboard',
      breadcrumb: [
        {
          text: 'Thông tin tài khoản',
          active: true,
        },
      ],
    },

  },
]
