import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import accounts from './routes/accounts'
import users from './routes/users'
import course from './routes/course'
import tagQuestion from './routes/tag-question'
import bankQuestion from './routes/bank-questions'
import exam from './routes/exam'
import file from './routes/file'
import examResult from './routes/exam-result'
import skill from './routes/skill'
import questionGroup from './routes/question-group'
import level from './routes/level'
import website from './routes/website'
import tag from './routes/tag'
import review from './routes/review-manager'
import userActivity from './routes/user-activity'
import quiz from './routes/quiz'
import openList from './routes/open-list'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...users,
    ...others,
    ...accounts,
    ...bankQuestion,
    ...exam,
    ...file,
    ...examResult,
    ...skill,
    ...questionGroup,
    ...level,
    ...website,
    ...tag,
    ...course,
    ...review,
    ...quiz,
    ...tagQuestion,
    ...userActivity,
    ...openList,

    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
