export default [
  {
    path: '/question-group/list',
    name: 'question-group-list',
    component: () => import('@/views/capacity/questionGroups/List.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Quản lý nhóm câu hỏi',
          active: true,
        },
      ],
    },

  },
  {
    path: '/question-group/edit/:id?/:name?',
    name: 'question-group-edit',
    component: () => import('@/views/capacity/QuestionGroup.vue'),
    meta: {
      action: 'edit',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Quản lý nhóm câu hỏi',
          to: { name: 'question-group-list' },
        },
        {
          text: null,
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[1].text = to.params.name ? to.params.name : 'Thêm mới'
      next()
    },
  },

]
