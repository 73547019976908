export default [
  {
    path: '/open/list',
    name: 'open-list',
    component: () => import('@/views/website/open/List.vue'),
    meta: {
      action: 'view',
      resource: 'website',
      breadcrumb: [
        {
          text: 'Quản lý khai giảng',
          active: true,
        },
      ],
    },

  },
]
