export default [
  {
    path: '/skill/list',
    name: 'skill-list',
    component: () => import('@/views/capacity/skills/List.vue'),
    meta: {
      action: 'view',
      resource: 'capacity',
      breadcrumb: [
        {
          text: 'Quản lý kỹ năng',
          active: true,
        },
      ],
    },

  },
]
